import RuleConfigAPICommunicator from 'services/rule-config-api-communicator';

import TenantManagementService from 'services/tenant/tenant-management-service';
import config from 'utils/helpers/config-utils/config';
import { API_PARAMS, API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';
import { IntegrationTestDto } from 'modules/configuration/settings/sftp-config/service/dto/integration-test-dto';

export class SftpApiCommunicator extends RuleConfigAPICommunicator {
  constructor() {
    super('sftp');
  }

  baseUrl(tenantId: string) {
    return `${`${config.caseManagerServer}/configuration/v1`}/${this.requestMapping}/${
      tenantId ?? TenantManagementService.getActiveTenantId()
    }`;
  }

  detokenizeConfig(instanceUniqueId: string) {
    const apiUrl = API_URLS.config.sftpConfiguration.detokenize
      .replace(API_PARAMS.tenantId, TenantManagementService.getActiveTenantId() || '')
      .replace(API_PARAMS.id, instanceUniqueId);
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  executeIntegrationTest(payload: IntegrationTestDto) {
    const apiUrl = API_URLS.config.sftpConfiguration.integrationTest.replace(
      API_PARAMS.tenantId,
      TenantManagementService.getActiveTenantId() || '',
    );
    return this.postJSONForTenant(apiUrl, payload, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  executeConnectionTest(payload: IntegrationTestDto) {
    const apiUrl = API_URLS.config.sftpConfiguration.connectionTest.replace(
      API_PARAMS.tenantId,
      TenantManagementService.getActiveTenantId() || '',
    );
    return this.postJSONForTenant(apiUrl, payload, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }
}
