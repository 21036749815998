// DO NOT ADD ROOT TENANT IDS, check for duplicates then add accordingly
// USE dev, test, qa, prod, multipleEnviroments nodes
const TENANT_IDS = {
  multipleEnviroments: {
    demo: '25721f57-038b-4b71-884a-e18f85c01288',
    six: 'd5ece94c-6aa8-4974-8df3-e429c8d22d20',
    mapFinTech: 'e81bd458-baa9-4a02-9324-3a6568c90090',
    one23Signed: '4057a24e-72aa-11ea-bc55-0242ac130003',
    creditSuisse: 'c6e21f4c-6e01-4d43-bebf-ff8304fd7439',
    subtenantForCounterpartyGrouping: '50442bd3-1086-40ee-a028-2f05a27144ee',
    bbAmericaEndToEndOpening: 'b66379f6-ac78-44c2-b3b3-54d97d6857a8',
    bbAmericaEndToEndAutoClosing: 'f8acf0f2-b5bc-46f5-9684-a71bff97eef6',
    nab: {
      investigation: 'ac0cb0fe-5532-41b3-9ad6-f8e6b11585dd',
    },
    dLocal: {
      investigation: 'bac94713-4c52-4b8e-8d9d-b102b8af55f0',
    },
    dockFinancial: {
      b2cE2e: '87b2cb6c-28a1-4334-9524-1ff597a40633',
      b2bE2e: 'fb006475-332a-4f84-8711-abfb3db3f3ce',
    },
    tenantForConfigurationChangeTesting: '7f3ecd8e-4658-4e38-afaa-f0a68d69c723',

    endToEnd: 'd1ce8467-aadf-4479-84e8-e0dbe1cea838',
    masterTenantTest: 'f0f52591-01ea-4ac4-8c7d-5f47c20cc391',
    rulesV2: '5c5216c1-bc95-437f-9be9-486b64327857',
    lexisNexisTesting: '38cf69ef-c5cb-4c6c-a923-4b449e643e75',

    hawkConfig: '94e248c9-2ff1-43e7-9989-df5c8ba4257a',

    endToEndConfig: 'd1ce8467-aadf-4479-84e8-e0dbe1cea839',

    myBankIntl: 'fb348f7a-186b-42ce-94a0-6ad6dbc3efa5',

    tobiasz: '5ef406b8-7b80-4f15-be7b-1b5d21586ec6',
    banking: {
      karol: 'e54b6364-4934-4153-bd0d-d98ccc284e00',
    },
    e2e: {
      e2e_import_dow_jones: '98c1e63e-c679-407b-90a3-68b50dd33c5e',
      e2e_import_info4c: 'af96d0cc-2500-4f13-a84a-8cf648d34489',
      e2e_import_lexis_nexis: '9043312e-858a-4938-8374-b57e434659c9',
    },
  },
  dev: {
    framl: 'fa43c7b7-4bc9-4d9b-938b-4b60ca188c19',
    banking: {
      oleg: 'b7f29013-2383-4ada-a0b5-69f5882479f7',
      maciej: '18605587-f1bb-48c6-a6cc-b6659cedd617',
      thinh_nguyenduc: '1c124b72-bc53-4c25-b9ae-a131bb244872',
      vishal: '991f15de-79b5-4d54-816c-dea2bc0ee94d',
    },
    amnis: {
      endToEnd: '48f557e5-38e0-438e-a041-27b970df4a3d',
    },
    wordline: {
      endToEnd: '10b3bebd-84e2-4ee1-b968-6c03775e2609',
    },
    MY_TENANT_JAKUB: {
      jakubsub1: 'c794745a-21b1-4ab0-917d-ada953ad2356',
    },
    phi: '8f78f648-0041-4bee-aeb8-536d0ce77499',
    rich: '775d13e6-3af5-428a-a129-aa87ee734327',
    adrien: '81f516de-a666-4a21-8d00-6de1529a726e',
    marcin: '1fc43403-3bda-485c-9529-e49d1bc41351',
  },
  test: {
    ebury: {
      playground: '0a086107-b382-46c6-9aea-d8965ce97490',
      integration: '16ad9dc1-8275-49b2-84e7-b30ba0057362',
    },
    eu_banking_demo: 'db924b5f-975e-4955-8b92-188e613173ff',
    aml_demo: 'd00bcfb7-5bfd-4b9c-a0bf-84b79fee0564',
    aml_demo_us: '9a63b41f-8771-4110-9c2e-b10fe695d57b',
    fraud_demo: '385d0ebc-d2ac-47aa-8a24-9e8cdcdbce01',
    banking: {
      maciej: '06356d7f-b964-401f-b90d-1cdb7f94bcd0',
      thinh_nguyenduc: 'e7e3cced-7329-47b2-bcd3-3f68f0140c25',
      paola: '3cb8111b-b2bf-4551-9e7a-0c46eb30a59a',
      vishal: 'c1da9877-4539-498c-8493-26e2fc5f0807',
    },
    framl: {
      demo: '94086d48-1d56-4d8f-b727-c378d803471e',
    },
    david_demo: '5bb8cc35-7561-4ada-af82-3e0c1fed6eac',
    csi: {
      demo: '32f2f6a8-6d48-41e8-9e40-2a73790c24f2',
    },
    michael: '75d7bb8d-c6f9-4add-9d50-2ee650d26ca5',
    jeff_test: '423345ed-46bf-42e8-930a-dc0e711b83c0',
    product_test: 'ef4da35f-1eda-4704-9144-07d4619fa1b8',
    delum_demo: 'f1dcb05c-7d2f-419b-953c-54b2d0513e94',
    worldline: {
      endToEnd: 'e103688a-c0d3-433d-9fa7-4bbfe718078e',
    },
    batchSandbox: {
      endToEnd: '2348ed79-7060-4cb0-b939-059cd8fec644',
      vrPay: '65d2ee96-a41f-4dbf-81ff-c5d4e62511ed',
    },
    amnis: {
      investigation: '6cbc31c3-b767-49f5-bee0-f38630e04453',
    },
    MY_TENANT_JAKUB: {
      jakubsub1: 'c794745a-21b1-4ab0-917d-ada953ad2356',
    },
    weavr: {
      onze: '268a7418-f49a-4cef-b85c-b2bf80dcd9b9',
      benapp: '5ca4f5db-b8da-4606-924a-6682e2d89033',
      qaTenant: 'bc791827-fbbe-4158-a259-cf7ef6db3d6e',
    },
    commerzbank: {
      demo: 'db924b5f-975e-4955-8b92-188e613173ff',
    },
    raiffeisenBank: {
      uat: '37d1177e-ae1a-48a8-95f0-d9bf25f0cac9',
      staging: 'c2596cb5-c678-4244-9842-fcce9f4a05c3',
    },
    phi: '1be0e07c-3c78-4e4d-8d82-a77566cdb6c7',
    kim: '516313a7-ce12-42b1-834b-fec9a01bbabb',
    marcin: 'f43cee6d-212b-4a67-8294-755784fa6a86',
    bbkGroupDemo: 'f1dcb05c-7d2f-419b-953c-54b2d0513e94',
    fior: {
      testing: '9f149020-8c11-447c-9662-1d41c7b4a3d6',
    },
  },
  qa: {
    subtenantForCounterpartyGrouping: '07b2aabc-dfd4-4965-82c3-3ef7b8aee10d',
    amlDemoUs: '9a63b41f-8771-4110-9c2e-b10fe695d57b',
    maciej: '11102a96-f1f3-4470-a7f3-05b91d61d44f',
    metamap: {
      playground: '26a5be2e-cd2f-4783-946a-6a86467c999f',
      demo: 'b9099492-004b-4212-ad24-f826dc53b9f6',
    },
    csi: {
      demo: '32f2f6a8-6d48-41e8-9e40-2a73790c24f2',
    },
    zalando: {
      testing: '6df3e8de-3486-406b-954d-aa905953bd9d',
      integration0eb: '0eb7b25c-d327-4d2c-a4c3-ced0f838c7b3',
      integrationb27: 'b2724b31-c50b-48b8-bedd-425f141026e6',
    },
  },
  prodUs: {
    csi: {
      testing: '24b7e623-b428-4e4b-b94a-ff4c7984177c',
      bank118poc: '70797ebf-d9c3-4b4f-b89e-19ae6288d87b',
    },
  },
  prod: {
    ebury: {
      main: '4be15851-fe04-4a45-adcd-ff66be7750aa',
      staging: '1b005861-cd82-43ab-99bc-7c441d862695',
    },
    fior: {
      root: 'f2bad14e-a25f-411a-b6c0-cf93f2adf9d5',
      staging: '9372f67f-4cf5-43db-9f24-9e9c433f420c',
    },
    metamap: {
      root: 'f0cacdfe-5609-47ac-95cd-13226c62e042',
      stagnig: '2f621f6b-c882-4f38-b38c-dec0b1f43d9e',
      xendit: '4d8f318a-ae6c-44d4-ab9b-b0f06720b64f',
    },
    otto: {
      staging: '5ee93267-6020-452f-a27b-bc28b42c5130',
    },
    tameed: {
      root: '2c508e29-4adc-4e3f-ae5a-04beb41eae1b',
      integration: '02766b2f-e58e-4989-b0af-3573a0049a1c',
    },
    worldline: 'b83a0e18-ff36-4bc5-a306-7ad40df9f96a',
    zalando: {
      staging: '6edc9e84-0cfb-4141-a827-c009a9f5d312',
      main: 'a9bc9037-6c13-43b9-9b6c-0fbbea28acf4',
    },
    phi: '20338b2d-6efe-4739-82f4-f683b6545c51',
    kim: '0a703879-9b74-4873-a399-38ef929377cf',
    rich: '562a0342-06f2-41ea-a099-be51644af199',
    algbra: '8bcf041b-2c4f-45bb-83bc-4f339da59363',
    marcin: 'b2634a0d-e430-4789-b413-ada41abc0c13',
  },
  // for CRR testing:
  crr: {
    mccTestGw: '82a6187c-730d-4067-9558-f938a0026f3f',
    crrGenTest: '03df3d51-5681-4322-b9e5-8830c9f7c24e',
    kim: {
      dev: 'e37e6c55-5aad-40b0-b9ac-492b1070501f',
      test: '332d5324-81e0-4125-b558-9c19a535960a',
    },
  },
  // for rule category testing
  galaxyFinx: {
    integration: 'f81d0542-1af8-4b38-9da1-fe3fda7329e4',
    master: '8c544e99-f416-4314-a0bf-5c5885a1dc7b',
  },
  csi: {
    demo: '32f2f6a8-6d48-41e8-9e40-2a73790c24f2',
    amlDemo: 'f7a9b55c-2f61-4761-b107-b8121d28a50d',
    screeningDemo: '05da42dd-ea52-45ab-83f2-30641bbb7a86',
    fraudDemo: '64bb9200-94d3-497a-a5f8-33b0ceae13d7',
  },
  mercedes: {
    master: '488c1817-4e57-4c3d-80c8-cce46d9a92a0',
    integration: '930370de-727e-4612-b44a-4de1ca63cd5c',
    sandbox: '32f45695-3c19-47cf-9c62-82b7fa56a415',
    preprod: '2f337144-2d22-4b87-913e-d4c0cb8557d7',
    investigation: '4349bb7b-318b-4a93-ae3e-54f9f38b3fc7',
    jakovsPlayground: '180a2bf6-09f0-4fcb-9831-0c472f566ae6',
    mbldDevelopment: '77c9ffc4-e9ca-46c6-8182-fdd9441e27a1',
    mbldIntegration: '72ef69c9-fc3e-497c-941e-a0550cac7a7d',
  },
};

export const DC_TEAM_TEST_TENANTS = [
  TENANT_IDS.multipleEnviroments.tobiasz,
  TENANT_IDS.dev.marcin,
  TENANT_IDS.dev.MY_TENANT_JAKUB.jakubsub1,
  TENANT_IDS.test.marcin,
  TENANT_IDS.prod.marcin,
];

export default TENANT_IDS;
