import moment from 'moment';
import Default from 'utils/constants/default';

/**
 * generic used for dev, test
 * live uses prod
 * auth0 object is not used anymore but being retrieved from vault service
 * TODO should be removed & tested
 */
const AUTHORIZATION_DOMAIN = {
  generic: {
    domain: 'hawkai-aml-platform.eu.auth0.com',
    clientID: 'DkVIbArxvU0OXNeStzg4k0Zzpd4BUyU9',
    redirectUri: `${window.location.origin}/callback`,
    responseType: 'token id_token',
    audience: 'case-manager-api',
    scope: 'openid profile email',
  },
  prod: {
    domain: 'aml-ai.eu.auth0.com',
    clientID: 'sk4EyJyNwvcjNZnHlR8GaBcGzaiZwKno',
    redirectUri: `${window.location.origin}/callback`,
    responseType: 'token id_token',
    audience: 'aml-ai.net backend',
    scope: 'openid profile email',
  },
};

/**
 * enviroment names mapped from kustomize
 */
const ENV_NAME = {
  dev: 'dev',
  test: 'test', // test & nab-dev
  qa: 'qa',
  prod: 'live',
  nabQa: 'nab-qa',
  nabProd: 'nab-prod',
};

/**
 * default object for env constants
 */
const ENV_CONFIG = {
  auth0:
    window.REACT_APP_ENV === ENV_NAME.prod
      ? AUTHORIZATION_DOMAIN.prod
      : AUTHORIZATION_DOMAIN.generic,
  name: window.REACT_APP_ENV,
  expiredAfterMinutes: window.REACT_APP_EXPIRED_AFTER_MINUTES,
  caseManagerServer: window.REACT_APP_CASEMANAGER_SERVER,
  sanctionServer: window.REACT_APP_SANCTION_SERVER,
  KIBANA_URL: window.REACT_APP_KIBANA_URL,
  FRONTEND_KIBANA_URL: window.REACT_APP_FRONTEND_KIBANA_URL,
};

/**
 * configs for our application
 */
const config = ENV_CONFIG;
config.baseUrl = `${ENV_CONFIG.caseManagerServer}/case-manager-backend/v1`;
config.baseUrlV2 = `${ENV_CONFIG.caseManagerServer}/case-manager-backend/v2`;

config.customerRiskRatingUrl = `${ENV_CONFIG.caseManagerServer}/customer-risk-rating`;

config.countryRiskBaseUrl = `${ENV_CONFIG.caseManagerServer}/countryrisk/v1`;
config.volumeRiskConfigBaseUrl = `${ENV_CONFIG.caseManagerServer}/assessment-engine/v1`;
config.sanctionBaseUrl = `${ENV_CONFIG.sanctionServer}/hawkai-api/v1`;
config.decisionEngineBaseUrl = `${ENV_CONFIG.caseManagerServer}/decision-engine/v1`;
config.usageRiskBaseUrl = `${ENV_CONFIG.caseManagerServer}/usagerisk/v1`;
config.accountBlacklistBaseUrl = `${ENV_CONFIG.caseManagerServer}/accountblacklist/v1`;
config.pepSanctionBaseUrl = `${ENV_CONFIG.caseManagerServer}/pepsanction/v1`;
config.labelEngineBaseUrl = `${ENV_CONFIG.caseManagerServer}/label-engine/v1`;
config.auth0BaseUrl = `${ENV_CONFIG.baseUrl}/auth0`;
config.assessmentEngineUrl = `${ENV_CONFIG.caseManagerServer}/assessment-engine/v1`;
config.smurfingUrl = `${ENV_CONFIG.caseManagerServer}/smurfing/v1`;
config.reprocessingUrl = `${ENV_CONFIG.caseManagerServer}/reprocessing/v1`;
config.reprocessingV2Url = `${ENV_CONFIG.caseManagerServer}/reprocessing/v2`;
config.experimentationUrl = `${ENV_CONFIG.caseManagerServer}/experimentation/v1`;
config.configurationUrl = `${ENV_CONFIG.caseManagerServer}/configuration/v1`;
config.configurationUrlV2 = `${ENV_CONFIG.caseManagerServer}/configuration/v2`;
config.configurationV0Url = `${ENV_CONFIG.caseManagerServer}/configuration`;
config.userUrl = `${ENV_CONFIG.baseUrl}/users`;
config.tenantManagementUrl = `${ENV_CONFIG.caseManagerServer}/tenant-management/v1`;
config.tenantManagementUrlV2 = `${ENV_CONFIG.caseManagerServer}/tenant-management/v2`;
config.auditTrailUrl = `${ENV_CONFIG.caseManagerServer}/audit-trail`;
config.caseNotifications = `${ENV_CONFIG.baseUrl}/case-notifications`;
config.screeningUrl = `${ENV_CONFIG.caseManagerServer}/screening/v1`;
config.caseStatisticsUrl = `${ENV_CONFIG.caseManagerServer}/case-statistics/v1`;
config.csvCasesExporterUrl = `${ENV_CONFIG.caseManagerServer}/csv-exporter/cases/v1`;
config.gatewayBatchUrl = `${ENV_CONFIG.caseManagerServer}/gateway-batch`;

config.SESSION_EXPIRE_AFTER_MINUTES = 15;
config.APP_ROOT_URL = '/caseManager';
config.APP_ROOT_URL_CUSTOMERS = '/customers';

// load dates for group search and case search dates

Object.keys(window)
  .filter(
    (variable) =>
      variable.indexOf('REACT_APP_GROUP_SEARCH_DATE_') === 0 ||
      variable.indexOf('REACT_APP_CASE_SEARCH_DATE_') === 0,
  )
  .forEach((variable) => {
    config[variable] = window[variable];
  });

function getGroupSearchDateForTenantId(tenantId) {
  const stringDate = config[`REACT_APP_GROUP_SEARCH_DATE_${tenantId?.replace(/-/g, '_')}`];
  if (stringDate) {
    return moment(stringDate, 'DD-MM-YYYY').get();
  }

  return undefined;
}

function getCaseSearchDateForTenantId(tenantId) {
  const stringDate = config[`REACT_APP_CASE_SEARCH_DATE_${tenantId?.replace(/-/g, '_')}`];
  if (stringDate) {
    return moment(stringDate, 'DD-MM-YYYY').get();
  }

  return undefined;
}

export default config;

export function getTenantDefaultCurrency(tenantConfig) {
  if (tenantConfig === undefined) {
    return Default.CURRENCY;
  }

  return tenantConfig.amountInBaseCurrency || Default.CURRENCY;
}

export { ENV_NAME, getGroupSearchDateForTenantId, getCaseSearchDateForTenantId };
